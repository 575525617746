/* eslint-disable no-console */
import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import styles from './FormQuote.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../../components/Button/Button';
import TripArea from './TripArea/TripArea';
import PeriodArea from './PeriodArea/PeriodArea';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { submitACotizar } from './submit/submit';
import { B2B2CContext } from '../../../../components/B2B2CStateParent/B2B2CStateParent';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import PersonAreaV2 from './PersonArea/PersonAreav2';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const FormQuote = props => {
  const { formType, backUrl, CONFIG_B2B2C, setStep } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const CONFIG_TEXT_FORMQUOTE = CONFIG_B2B2C.quote.text.text_formQuote(intl);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { actions, utils, state } = useContext(B2B2CContext);
  const {
    actions: actionsState,
    utils: { getSelectedCard },
  } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const history = useHistory();
  const themes = getGlobalTheme();

  const initialValues = useMemo(
    () =>
      CONFIG_B2B2C.quote.form.initialValues(
        utils.getQuote(),
        getSelectedCard()
      ),
    [state.formDataCotizar.Quote]
  );
  const validationSchema = useMemo(
    () => CONFIG_B2B2C.quote.form.validationForm(intl),
    [intl]
  );

  const submit = async values => {
    try {
      trackEventUserAction('#### (B2B2C) VAI FAZER O QUOTE', values);
      actionsState.modal.closeModal();
      actionsState.loadingFullScreen.showLoadingFullScreen(intl.LOADING, true);
      const _props = {
        values,
        formType,
        actions,
        utils,
        CONFIG_B2B2C,
        trackEventUserAction,
      };
      await submitACotizar(_props);

      trackEventUserAction('#### (B2B2C) INDO PARA O STEP 2', null);
      setStep(2);
    } catch (error) {
      console.log(error);
    } finally {
      actionsState.loadingFullScreen.hideLoadingFullScreen();
    }
  };

  const back = event => {
    event.preventDefault();
    history.push(backUrl);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {props => {
        const { handleSubmit } = props;

        return (
          <form
            id="mainContent"
            onSubmit={handleSubmit}
            data-testid="FullFormCliamCarRental"
          >
            <div>
              <div className={`row`}>
                <label className={`col-12 ${styles.title} ${styles[themes]}`}>
                  {CONFIG_TEXT_FORMQUOTE.title}
                </label>
                <div className="col-12 col-md-6">
                  {CONFIG_TEXT_FORMQUOTE.description
                    .split('--')
                    .slice(0, 2)
                    .map(text => (
                      <label
                        key={text}
                        className={`${styles.subtitle} ${styles[themes]}`}
                      >
                        {text}
                      </label>
                    ))}
                </div>
                <div className="col-12 col-md-6">
                  {CONFIG_TEXT_FORMQUOTE.description
                    .split('--')
                    .slice(2)
                    .map(text => (
                      <label
                        key={text}
                        className={`${styles.subtitle} ${styles[themes]}`}
                      >
                        {text}
                      </label>
                    ))}
                </div>
              </div>
              <TripArea {...props} CONFIG_B2B2C={CONFIG_B2B2C} />
              <PeriodArea {...props} CONFIG_B2B2C={CONFIG_B2B2C} />
              <PersonAreaV2 {...props} CONFIG_B2B2C={CONFIG_B2B2C} />

              <div className={`${styles.footer} ${styles[themes]}`}>
                <div className={styles.divButton}>
                  <Button onClick={back} type="axaBlueBorder">
                    {intl.BTN_BACK}
                  </Button>
                </div>
                <div className={styles.divButton}>
                  <Button type="axaBlue">{intl.BTN_QUOTE}</Button>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormQuote;
