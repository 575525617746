import { useState, useEffect } from 'react';
import styles from './MenuCountryList.module.scss';
import SubMenuCountry from './SubMenuCountry/SubMenuCountry';
import { useIntl } from '../../intl';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import * as translations from './intl';
import { joinClassNames } from '../../utils/classNames';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

const MenuCountryList = ({ color }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { countryFullName, translate } = useIntl();
  const intl = translate(translations);
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  useEffect(() => {
    setOpenSubMenu(false);
  }, [countryFullName]);

  useEffect(() => {
    const wrapperMenuCountryList = document.getElementById(
      'wrapperMenuCountryList'
    );
    const btnMenuCountryList = document.getElementById('btnCountryList');
    wrapperMenuCountryList.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setOpenSubMenu(false);
        btnMenuCountryList.focus();
      }
    });
  }, []);

  return (
    <div
      className={`${styles.menuCountry}  hideOnMobile`}
      onClick={() => dataLayerContentMenuTrack('CTA Country')}
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpenSubMenu(false);
        }
      }}
      id="wrapperMenuCountryList"
    >
      <button
        aria-expanded={openSubMenu}
        type="button"
        id="btnCountryList"
        className={styles.buttonCountry}
        onClick={() => {
          setOpenSubMenu(!openSubMenu);
        }}
      >
        <span style={{ color: color }}>{countryFullName}</span>
        <span
          className={joinClassNames(
            styles.icon,
            styles[theme],
            color === '#FFFFFF' ? undefined : styles.inverse
          )}
        >
          <ChevronGeneric
            rotate={!openSubMenu ? 0 : 180}
            width={20}
            height={20}
            data-testid="iconChevron"
            aria-label={intl.ICON_OPEN_MENU_COUNTRIES_LIST}
            color="inherit"
          />
        </span>
      </button>
      {openSubMenu && <SubMenuCountry />}
    </div>
  );
};

export default MenuCountryList;
