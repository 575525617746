import React, { useContext } from 'react';
import styles from './BlueBox.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

import Button from '../../../components/Button/Button';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import ButtonLogin from '../../../components/ButtonLogin/ButtonLogin';
import lockMaster from '../../../assets/images/icon/master/svg-icon-lock-master.svg';
import lockAfluent from '../../../assets/images/icon/afluent/svg-icon-lock-afluent.svg';
import StepCircle from '../StepCircle/StepCircle';
import { isMobile } from 'react-device-detect';
import Bluebox from '../../../components/Bluebox';
import { dataLayerAppSelectTrack } from '../../../utils/GTM_helper';
import LoginButton from '../../../componentsV2/LoginButton/LoginButton';

const BlueBox = props => {
  const { benefitDetail } = props;
  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const isLogged = utils.isUserLogged();
  const isRappi =
    benefitDetail && benefitDetail.name && benefitDetail.name.includes('Rappi');

  const buttonRedeem =
    benefitDetail &&
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.redeem &&
    benefitDetail.redeem_info.redeem.button;
  const title =
    benefitDetail &&
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.bluebox &&
    benefitDetail.redeem_info.bluebox.title;
  const additional_content =
    benefitDetail &&
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.redeem &&
    benefitDetail.redeem_info.redeem.additional_content;

  const lockIcon = isAfluent() ? lockAfluent : lockMaster;

  const redirectHyperativa = () => {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = benefitDetail.redeem_info.redeem.url;
  };

  const isLoggedAndMobile = () => {
    if (isRappi) {
      if (isLogged && isMobile) {
        return true;
      } else return false;
    } else if (isLogged) {
      return true;
    } else return false;
  };

  const buttons = (
    <>
      {isLogged && isRappi && !isMobile ? null : isLogged ? (
        <Button type="gold" onClick={redirectHyperativa}>
          {buttonRedeem && buttonRedeem}
        </Button>
      ) : (
        <LoginButton
          redirect={`/your-subscription-benefits/${benefitDetail.external_reference}`}
          type="Secondary"
          label={intl.BUTTON_LOGIN}
          surface="Inverse"
          className={styles.loginButton}
        />
      )}
    </>
  );

  const showPoints = () => {
    if (!isRappi) {
      return true;
    } else if (isRappi && isMobile) {
      return true;
    } else return false;
  };

  return (
    <>
      <div className={`${styles.boxContainer} ${styles[theme]}`}>
        <h1 className={showPoints() ? styles.title : styles.titleDownloadApp}>
          {showPoints() ? title : intl.DOWNLOAD_APP}
        </h1>

        <div className={styles.steps}>
          {showPoints() ? (
            <>
              <div className={styles.stepItem}>
                <StepCircle number={1} concluded={isLoggedAndMobile()} />
                <span>{`${intl.REDEEM_STEP_1} ${
                  additional_content ? additional_content : ''
                }`}</span>
              </div>
              <div className={styles.stepItem}>
                <StepCircle number={2} />
                <span>{intl.REDEEM_STEP_2}</span>
              </div>
              <div className={styles.stepItem}>
                <StepCircle number={3} />
                <span>{`${intl.REDEEM_STEP_3}`}</span>
              </div>
              <div className={styles.stepItem}>
                <StepCircle number={4} />
                <span>{`${intl.REDEEM_STEP_4(
                  isRappi ? 'Rappi' : benefitDetail.name
                )}`}</span>
              </div>
            </>
          ) : (
            <>
              <Bluebox.SubContainer>
                <Bluebox.Text
                  text={intl.SUBTITLE_RAPPI}
                  margin={false}
                  center
                />
              </Bluebox.SubContainer>
              <Bluebox.SubContainer center>
                <Bluebox.QRCode
                  link={`${window.location.origin}/download-app/store?utm_source=qr1&utm_medium=QR&utm_campaign=app`}
                />
              </Bluebox.SubContainer>

              <div className={styles.imageLink}>
                <Bluebox.SubContainer inline>
                  <Bluebox.ImageLink
                    imageUrl={intl.IMAGE_APP_STORE}
                    altImage="App Store"
                    href={process.env.REACT_APP_IOS_STORE.toString()}
                    onClick={() => dataLayerAppSelectTrack('Apple Store')}
                  />
                  <Bluebox.ImageLink
                    imageUrl={intl.IMAGE_PLAY_STORE}
                    altImage="Google Play"
                    href={process.env.REACT_APP_ANDROID_STORE.toString()}
                    onClick={() => dataLayerAppSelectTrack('Google Play')}
                  />
                </Bluebox.SubContainer>
              </div>
            </>
          )}
        </div>

        <div className={styles.buttonArea}>{buttons}</div>

        <div className={styles.notAdditional}>
          {showPoints() || !isLogged ? (
            <>
              <img src={lockIcon} alt="" />
              <p
                dangerouslySetInnerHTML={{
                  __html: intl.NOT_ADDITIONAL_CHARGES,
                }}
              ></p>
            </>
          ) : (
            <p>{intl.AVAILABLE_IN_STORES}</p>
          )}
        </div>
      </div>
    </>
  );
};

export default BlueBox;
