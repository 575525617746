import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const AddIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path d="M8 1V7" stroke={config_icon.primary} strokeWidth={2} />
      <path d="M8 11V15" stroke={config_icon.secondary} strokeWidth={2} />
      <path d="M1 8H15" stroke={config_icon.primary} strokeWidth={2} />
    </svg>
  );
};

export default AddIcon;
