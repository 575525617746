/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import * as translations from './intl';
import { IntlContext } from '../../../../../intl';
import styles from './Notification.module.scss';
import Button from '../../../../../components/Button/Button';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { NOTIFICATION } from '../../const';

import { claimsServices, notificationsService } from '../../../../../services';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import Loading from '../../../../../components/Loading/Loading';
import ClaimView from '../../../../Claims/ClaimView/ClaimView';
import { isAllowedClaimReimbursmentUpdate } from '../../../../../utils/claims_name';
import { productsAndBenefitsServices } from '../../../../../services/index';
import { formatCustomDate } from '../../../../../utils/date';
import ChevronGeneric from '../../../../../assets/icons/ChevronGeneric';
import UnarchiveIcon from '../../../../../assets/icons/UnarchiveIcon';
import ArchiveIcon from '../../../../../assets/icons/ArchiveIcon';
import sanitize from '../../../../../utils/sanitize';
import FlightDetailsModal from '../../../../../components/RecentItem/FlightDetailsModal/FlightDetailsModal';
import {
  getFlightNumber,
  getFlightStatus,
  getIsFlightNotification,
} from '../../../../../utils/travels';
import { ENABLE_TRIP_ASSISTANCE } from '../../../../../utils/environments_variables';
import StatusChip from '../../../../../componentsV2/Chip/StatusChip/StatusChip';

const Notification = props => {
  const { data, updateFn, type, setReadNotification, checkNotification } =
    props;
  const { translate, idiom, idiomForApi, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils, actions } = useContext(StateContext);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const [showInfo, setShowInfo] = useState(false);
  const [loadingCheckClaim, setLoadingCheckClaim] = useState(false);
  const [ready, setReady] = useState(false);
  const [notificationContent, setNotificationContent] = useState(null);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const history = useHistory();

  const isClaim = data && data.entity && data.entity.type === 'CLAIMS';

  // The updated type for travel kit is 'TRAVELS', the old value 'FLIGHT_DETAIL' should be removed completely
  // once the change has been made in SF for all environments.
  const isFlight =
    (data && data.entity && data.entity.type === 'FLIGHT_DETAIL') ||
    (data && data.entity && data.entity.type === 'TRAVELS');

  const [, flightType] = getIsFlightNotification(notificationContent);
  const flightStatus = getFlightStatus(flightType);

  let icon =
    type === NOTIFICATION ? (
      <ArchiveIcon width={20} height={20} />
    ) : (
      <UnarchiveIcon width={20} height={20} />
    );

  const redirectLink = async identifier => {
    setLoadingCheckClaim(true);
    try {
      const { data: dataClaim } = await claimsServices.getClaimInfoByClaimId(
        identifier,
        idiom
      );
      if (
        isAllowedClaimReimbursmentUpdate(dataClaim.benefit_external_reference)
      ) {
        const { data: benefitData } =
          await productsAndBenefitsServices.getBenefitsDetailByProduct(
            binOrPan,
            dataClaim.benefit_external_reference,
            idiomForApi()
          );
        actions.modal.showModal(
          `
          ${intl.TITLE_CLAIM_VIEW(benefitData.name)} - ${
            dataClaim.existing_case_number
          }
        `,
          true,
          <ClaimView
            claimId={identifier}
            claimType={dataClaim.benefit_external_reference}
          />
        );
        setLoadingCheckClaim(false);
      } else {
        history.push('/profile/claims');
      }
    } catch (error) {
      setLoadingCheckClaim(false);
    }
  };

  const toggleMenu = async () => {
    if (!ready) {
      setReady(true);
    }
    setShowInfo(!showInfo);
    if (type === NOTIFICATION && !data.was_read) {
      await setReadNotification(data.notification_id);
    }
    if (notificationContent === null) {
      await loadNotificationContent();
    }
  };

  const loadNotificationContent = async () => {
    try {
      const response = await notificationsService.getUserNotificationDetail(
        cn,
        data.notification_id,
        idiom
      );
      setNotificationContent(response.data.content);
    } catch (error) {
      setNotificationContent('');
      console.log(error);
    }
  };

  const showFlightDetails = () => {
    if (ENABLE_TRIP_ASSISTANCE) {
      const flightNumber = getFlightNumber(data.title);

      const pathBase = `/travel-kit/details/${data.entity.identifier}`;
      const path = flightNumber ? `${pathBase}/${flightNumber}` : pathBase;
      history.push(path);
    } else {
      actions.modal.showModal(
        '',
        true,
        <FlightDetailsModal travelId={data.entity.identifier} />
      );
    }
  };

  return (
    <li
      className={`${styles.notificationsItem} ${styles[themes]}
        ${
          data.was_read === false && !ready
            ? styles.activeNotficationBorder
            : ''
        }
        ${data.isChecked ? styles.borderGold : ''}
      `}
      aria-label={`notificationItem-${type}-${data.notification_id}`}
      data-testid={`notificationItem-${type}`}
    >
      <div className={styles.left}>
        <div className={`${styles.text} ${styles[themes]}`}>
          <CheckBox
            name={`checkbox-${data.notification_id}`}
            value={data.isChecked}
            onClick={() =>
              checkNotification(data.notification_id, !data.isChecked)
            }
            dataTestid={`Checkbox-${data.notification_id}`}
          >
            <span
              className={
                data.was_read === false && !ready
                  ? `${styles.activeNotificationBlue} ${styles[themes]}`
                  : ''
              }
            >
              {isFlight ? (
                <div className={styles.flightStatus}>
                  <strong>{data.title}</strong>
                  {flightType && (
                    <StatusChip status={flightStatus} context="TRAVEL_KIT" />
                  )}
                </div>
              ) : (
                <strong>{data.title}</strong>
              )}
            </span>
          </CheckBox>
        </div>
      </div>
      <div
        className={`${styles.center} ${styles[themes]} ${
          styles.verticalDivider
        } ${showInfo ? styles.noBorder : ''}`}
      >
        <span>
          {formatCustomDate(
            data.received_at,
            'YYYY-MM-DD HH:mm:ss',
            idiom,
            country
          )}
        </span>
        <span className={`${styles.arrow} ${styles.showMoreActive}`}>
          <ChevronGeneric
            rotate={showInfo ? 180 : 0}
            aria-label={intl.IMAGE_ARROW}
            width={20}
            height={20}
            onClick={toggleMenu}
          />
        </span>
      </div>
      <div
        className={
          showInfo ? `${styles.right} ${styles.hideButtons}` : styles.right
        }
      >
        {isClaim && (
          <div className={`${styles.button} btnSelect`}>
            {loadingCheckClaim ? (
              <Loading />
            ) : (
              <Button
                id={`viewClaim-${data.notification_id}-closed`}
                type="borderBlue"
                onClick={() => redirectLink(data.entity.identifier)}
              >
                {intl.BTN_VIEW_CLAIM}
              </Button>
            )}
          </div>
        )}

        {isFlight && !showInfo && (
          <div className={`${styles.button} btnSelect`}>
            <Button
              id={`viewTravelDetails-${data.notification_id}-closed`}
              type="borderBlue"
              onClick={showFlightDetails}
            >
              {intl.BTN_FLIGHT_DETAILS}
            </Button>
          </div>
        )}

        <span
          className={`${styles.arrow} ${showInfo ? styles.showMoreActive : ''}`}
        >
          <ChevronGeneric
            rotate={showInfo ? 180 : 0}
            aria-label={intl.IMAGE_ARROW}
            width={20}
            height={20}
            onClick={toggleMenu}
          />
        </span>
      </div>
      {showInfo && (
        <>
          {notificationContent === null ? (
            <Loading />
          ) : (
            <div className={`${styles.detailContent} ${styles[themes]}`}>
              <div className={`${styles.detailContentText}`}>
                <div className={`${styles.showDetails} ${styles[themes]}`}>
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sanitize(notificationContent),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showInfo && (
        <div className={`${styles.detailContentActions} ${styles[themes]}`}>
          <div className={styles.detailContentButtons}>
            {isClaim && (
              <div className={styles.mainDetailsbutton}>
                {loadingCheckClaim ? (
                  <Loading />
                ) : (
                  <Button
                    type="gold"
                    id={`viewClaim-${data.notification_id}`}
                    onClick={() => redirectLink(data.entity.identifier)}
                  >
                    {intl.BTN_VIEW_CLAIM}
                  </Button>
                )}
              </div>
            )}

            {isFlight && (
              <>
                <div className={styles.mainDetailsbutton}>
                  <Button
                    id={`viewTravelDetails-${data.notification_id}`}
                    type="gold"
                    onClick={showFlightDetails}
                  >
                    {intl.BTN_VIEW_FLIGHT_DETAILS}
                  </Button>
                </div>
                <div
                  className={`${styles.buttonLeftDivider} ${styles[themes]}`}
                >
                  <Button
                    id={`viewTravelBenefits-${data.notification_id}`}
                    type="blueWithoutBorder"
                    onClick={() =>
                      history.push('/your-benefits/category/travel_benefits')
                    }
                  >
                    {intl.BTN_VIEW_TRAVEL_BENEFITS}
                  </Button>
                </div>
              </>
            )}

            <div className={styles.buttonArchive}>
              <Button
                id={`archiveAndUnarchive-${data.notification_id}`}
                type="blueWithoutBorder"
                onClick={async () => {
                  await updateFn(data.notification_id, !data.was_archived);
                }}
              >
                {icon}
                {type === NOTIFICATION ? intl.BTN_ARCHIVE : intl.BTN_UNARCHIVE}
              </Button>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

export default withRouter(Notification);
