import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../intl';
import {
  getIncidentDate,
  getItemAmountClaimed,
  getItemManufacturer,
  getItemModel,
  getItemPurchasePrice,
  getItemTypeOfLoss,
  getTotalAmountClaimed,
} from '../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { safeGet } from '../../../../utils/object';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import * as translations from './intl';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const CellphoneProtectionDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const [incident, setIncident] = useState({});
  const [cellphone, setCellphone] = useState({});
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const cellphone =
      safeGet(claimDetails, 'purchase_protection_items[0]') || {};
    setCellphone(cellphone);

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);
  }, [claimDetails]);

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DATE}
          value={getIncidentDate(incident, idiom)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TYPE_OF_LOSS}
          value={getItemTypeOfLoss(cellphone, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={getItemManufacturer(cellphone)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MODEL}
          value={getItemModel(cellphone)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_PRICE}
          value={getItemPurchasePrice(cellphone)}
        />
      </div>
      {cellphone && cellphone.is_repairable && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_REPAIR_VALUE}
            value={getItemAmountClaimed(cellphone)}
          />
        </div>
      )}
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getTotalAmountClaimed(totalAmountClaimed)}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} (${reimbursedCurrency})`}
          />
        </div>
      )}
    </div>
  );
};

export default CellphoneProtectionDetail;
