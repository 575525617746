import { useState, useContext, useRef, useEffect } from 'react';
import styles from './BellNotification.module.scss';

import { IntlContext } from '../../intl/index';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

import * as translations from './intl';
import { NotificationContext } from '../NotificationContextParent/NotificationContextParent';
import { sanitize } from 'dompurify';
import NotificationsIcon from '../../assets/icons/NotificationsIcon';
import Button from '../../componentsV2/Button/Button';

const BellNotification = ({ iconColor }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const {
    state: { bellNotification },
  } = useContext(NotificationContext);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const notifications = bellNotification.recentFiveNotificationsDetail;

  const renderMenuItem = notification => {
    return (
      <li
        key={notification.notification_id}
        className={`
      ${styles[theme]}
      ${notification.was_read ? '' : styles.new}
      `}
      >
        <p
          className={`${styles[theme]}
        ${notification.was_read ? '' : styles.new}`}
        >
          {notification.title}
        </p>
        <span
          dangerouslySetInnerHTML={{ __html: sanitize(notification.content) }}
        />
      </li>
    );
  };

  const openMenu = e => {
    e.stopPropagation();
    setShowMenu(true);
  };

  const closeMenu = event => {
    const containMenu =
      menuRef.current && menuRef.current.contains(event.target);
    const containButton =
      buttonRef.current && buttonRef.current.contains(event.target);

    if (!containMenu) {
      if (containButton) {
        event.stopPropagation();
      }
      setShowMenu(false);
    }
  };

  useEffect(() => {
    if (showMenu) document.body.addEventListener('click', closeMenu);
    else document.body.removeEventListener('click', closeMenu);

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [showMenu]);

  useEffect(() => {
    const wrapperBellNotification = document.getElementById(
      'wrapperBellNotification'
    );
    const btnBellNotification = document.getElementById('btnBellNotification');
    wrapperBellNotification.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setShowMenu(false);
        btnBellNotification.focus();
      }
    });
  }, []);

  return (
    <div
      className={styles.notificationContainer}
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowMenu(false);
        }
      }}
      id="wrapperBellNotification"
    >
      <button
        id="btnBellNotification"
        className={styles.boxIcon}
        onClick={showMenu ? closeMenu : openMenu}
        ref={buttonRef}
        aria-label={intl.NOTIFICATIONS}
        aria-expanded={showMenu}
      >
        <NotificationsIcon
          colorOverride={{ primary: iconColor, secondary: iconColor }}
        />
        {bellNotification && bellNotification.count > 0 && (
          <span className={`${styles[theme]} ${styles.number}`}>
            {bellNotification.count < 10 ? bellNotification.count : '9+'}
          </span>
        )}
      </button>
      {showMenu && (
        <div
          className={`${styles.containerList} ${styles[theme]}`}
          ref={menuRef}
        >
          <ul className={`${styles.menuNotifications} ${styles[theme]}`}>
            {notifications.map(notification => renderMenuItem(notification))}
          </ul>
          <div className={`${styles[theme]} ${styles.viewAll}`}>
            <Button
              link
              type="Tertiary"
              trailingIcon="Chevron Right"
              href="/profile/notifications#all"
              label={intl.VIEW_ALL}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BellNotification;
