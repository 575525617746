import { useContext } from 'react';
import styles from './Select.module.scss';
import { safeGet } from '../../utils/object';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../utils/sanitize';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import ChevronDownIcon from '../../assets/icons/ChevronDownIcon';
import { joinClassNames } from '../../utils/classNames';

const removeRequiredCharLabel = data => {
  return data && data.slice(-1) === '*'
    ? data.substring(0, data.length - 1)
    : data;
};

const Select = props => {
  let {
    label,
    name,
    value,
    error,
    onChange,
    onBlur,
    touched,
    defaultValue,
    dangerouslySetInnerHTML,
    disabled,
    dataTestid,
    defaultStyle,
    formikProps,
    hideArrow = false,
    selectRef,
    axa,
    shouldBeSmaller = false,
    helpText,
    describe,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  if (formikProps) {
    onChange = onChange || formikProps.handleChange;
    onBlur = onBlur || formikProps.handleBlur;
    error = safeGet(formikProps, 'errors.' + name);
    touched = safeGet(formikProps, 'touched.' + name);
    value = value ? value : safeGet(formikProps, 'values.' + name);
  }

  let className = '';
  if (value && !error) className += ' ' + styles.value;
  if (error && touched) className += ' ' + styles.error;
  if (defaultStyle) className += ' ' + styles.defaultStyle;
  const classMarginToIcon = helpText ? styles.marginToIcon : '';
  let classHideArrow = hideArrow ? styles.selectHideArrow : '';

  const formikError = error && '1';
  const { getGlobalTheme } = useTheme(axa);
  const themes = getGlobalTheme();

  const describeContent = describe
    ? `${intl.DESCRIBE_INFO_CONTENT} ${describe}`
    : undefined;

  return (
    <div
      className={joinClassNames(
        styles.Select,
        classHideArrow,
        classMarginToIcon
      )}
    >
      {(value || value === false) && (
        <label
          htmlFor={`select-${name}`}
          className={`${styles.label} ${styles[themes]}`}
        >
          {removeRequiredCharLabel(label)}{' '}
        </label>
      )}
      {!value && (
        <label
          htmlFor={`select-${name}`}
          className={`${styles.labelAlignedToRight} ${styles[themes]}`}
        />
      )}
      <select
        id={`select-${name}`}
        aria-label={describeContent}
        aria-describedby={`error-${name}`}
        aria-invalid={error && touched}
        formik-error={formikError}
        className={`${className} ${styles[themes]} ${
          shouldBeSmaller ? styles.shouldBeSmaller : ''
        }`}
        placeholder={label}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={event => {
          const clean = sanitize(event.target.value, {
            ALLOWED_TAGS: ['visa-tag'],
          });
          event.target.value = clean;
          onChange(event);
        }}
        onBlur={onBlur}
        disabled={disabled}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        data-testid={dataTestid}
        ref={selectRef}
        aria-expanded="true"
      >
        {props.children}
      </select>
      {error && touched && (
        <label id={`error-${name}`} className={styles.errorLabel} role="alert">
          {error}
        </label>
      )}
      {helpText && (
        <div className="input-group-prepend">
          <span
            data-tooltip
            datatitle={helpText}
            className={`${styles.groupText} ${styles[themes]}`}
          >
            <TooltipIcon axa={axa} aria-label={intl.TOOLTIP_ICON} />
          </span>
        </div>
      )}
      <ChevronDownIcon aria-hidden className={styles.chevronIcon} axa={axa} />
    </div>
  );
};

export default Select;
