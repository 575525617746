import React, { useContext, useState, useEffect } from 'react';

import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import PurchasedItem from './PurchasedItem/PurchasedItem';
import styles from './PurchaseProtectionDetail.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { getTotalAmountClaimed } from '../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const PurchaseProtectionDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const [purchasedItems, setPurchasedItems] = useState([]);
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    const purchasedItems =
      (claimDetails && claimDetails.purchase_protection_items) || [];
    setPurchasedItems(purchasedItems);

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);
  }, [claimDetails]);

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      {purchasedItems.map((item, index) => (
        <PurchasedItem key={index} item={item} itemNumber={index + 1} />
      ))}
      <div className="col-12">
        <h4 className={`${styles.ItemSeparator} ${styles[themes]}`}></h4>
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getTotalAmountClaimed(totalAmountClaimed)}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} (${reimbursedCurrency})`}
          />
        </div>
      )}
    </div>
  );
};

export default PurchaseProtectionDetail;
