import {
  ButtonHTMLAttributes,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { ButtonType } from './Button.types';
import { TrailingIconType } from './TrailingIcon/TrailingIcon.types';
import { ThemeType } from '../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../utils/classNames';
import styles from './Button.module.scss';
import TrailingIcon from './TrailingIcon/TrailingIcon';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import NavHashLink from '../../components/NavHashLink/NavHashLink';

interface ButtonImplementationBaseProps {
  id?: string;
  type: ButtonType;
  className: string;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  leadingIcon?: ReactNode;
  trailingIcon?: TrailingIconType;
  theme: ThemeType;
}

export interface ButtonButtonImplementationProps
  extends ButtonImplementationBaseProps {
  typeHtml?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface ButtonAnchorImplementationProps
  extends ButtonImplementationBaseProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  /**
   * Does the Link directs to an internal page (implements react-router-dom
   * Link), or external domain (implements a anchor(a) HTML tag).
   *
   * @defaultValue
   * true
   */
  internal?: boolean;
}

export const ButtonImplementation = ({
  id,
  className,
  label,
  ariaLabel,
  disabled,
  leadingIcon,
  trailingIcon,
  type,
  typeHtml = 'button',
  theme,
  onClick,
}: ButtonButtonImplementationProps) => (
  <button
    id={id}
    className={className}
    disabled={disabled}
    onClick={onClick}
    aria-label={ariaLabel}
    type={typeHtml}
  >
    {leadingIcon}
    {type != 'Icon' && <span>{label}</span>}
    <TrailingIconWrapper
      type={type}
      trailingIcon={trailingIcon}
      theme={theme}
    />
  </button>
);

export const AnchorImplementation = ({
  id,
  className,
  label,
  ariaLabel,
  disabled,
  leadingIcon,
  trailingIcon,
  type,
  theme,
  href,
  internal = true,
  target = internal ? undefined : '_blank',
  onClick,
}: ButtonAnchorImplementationProps) => {
  const intl = useMemoTranslations(translations);

  const commomProps = {
    id,
    className,
    'aria-disabled': disabled,
    'aria-label': `${ariaLabel || label}${
      internal ? '' : ` - ${intl.NEW_TAB}`
    }`,
    rel: internal ? undefined : 'noopener noreferrer',
    target: internal ? undefined : target,
    onClick,
  };

  return internal ? (
    <NavHashLink {...commomProps} to={href || ''}>
      {leadingIcon}
      {type != 'Icon' && <span>{label}</span>}
      <TrailingIconWrapper
        type={type}
        trailingIcon={trailingIcon}
        theme={theme}
      />
    </NavHashLink>
  ) : (
    <a {...commomProps} href={href}>
      {leadingIcon}
      {type != 'Icon' && <span>{label}</span>}
      <TrailingIconWrapper
        type={type}
        trailingIcon={trailingIcon}
        theme={theme}
      />
    </a>
  );
};

const TrailingIconWrapper = ({
  type,
  trailingIcon,
  theme,
}: {
  type: ButtonType;
  trailingIcon: TrailingIconType;
  theme: ThemeType;
}) =>
  type != 'Icon' &&
  !!trailingIcon && (
    <span
      className={joinClassNames(styles.trailingIconContainer, styles[theme])}
    >
      <TrailingIcon type={trailingIcon} />
    </span>
  );
