import autoRentalSvg from '../assets/images/certificates/Auto Rental.svg';
import garantiaExtendidaSvg from '../assets/images/certificates/Garantia extendida.svg';
import seguroCompraSvg from '../assets/images/certificates/Seguro Compra.svg';
import travelCertificateSvg from '../assets/images/certificates/Travel Certificate.svg';
import { ThemeColorsObject } from '../themes/ThemeContextParent/themes';

const certificateImages = {
  billete_auto_rental_insurance: autoRentalSvg,
  certificate_extended_warranty: garantiaExtendidaSvg,
  certificate_seguro_compra: seguroCompraSvg,
  travel_insurance: travelCertificateSvg,
};

export const certificateImagesByCertificateType = certificateType =>
  certificateImages[certificateType];

export const getIconThemeColors = (
  colors: ThemeColorsObject,
  surface: 'OnSurface' | 'Inverse',
  color: 'Monotone' | 'Duotone',
  disabled: boolean
) => {
  if (disabled) {
    const colorKey =
      surface === 'OnSurface' || color === 'Duotone' ? 'graphics' : 'surface';
    const disabledColor = colors[`disabled-${colorKey}`];
    return {
      primary: disabledColor,
      secondary: disabledColor,
    };
  }

  return surface === 'OnSurface'
    ? {
        primary: colors['icon-on_surface-primary'],
        secondary:
          colors[
            `icon-on_surface-${color === 'Monotone' ? 'primary' : 'secondary'}`
          ],
      }
    : {
        primary: colors['icon-inverse-primary'],
        secondary:
          colors[
            `icon-inverse-${color === 'Monotone' ? 'primary' : 'secondary'}`
          ],
      };
};
