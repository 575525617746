import React, { useContext } from 'react';
import styles from './StepClaim.module.scss';
import StepClaimItem from './StepClaimItem/StepClaimItem';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { useIntl } from '../../../intl';

const StepClaim = ({
  currentStep,
  axa = undefined,
  stepNames = ['', '', ''],
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useIntl();

  const theme = getGlobalTheme();
  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <div className={styles.stepsBackground}></div>
      <div
        aria-label={intl.ARIA_STEPS(
          currentStep,
          stepNames.length,
          stepNames[currentStep - 1]
        )}
        className={styles.steps}
        tabIndex={0}
      >
        {stepNames.map((name, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div
                className={`${styles[theme]} ${styles.line} ${
                  axa ? styles.axa : ''
                } ${currentStep > index + 1 ? styles.active : ''}`}
              />
            )}
            <StepClaimItem
              selected={currentStep === index + 1}
              concluded={currentStep > index + 1}
              axa={axa ? 1 : 0}
              name={name}
            >
              {index + 1}
            </StepClaimItem>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StepClaim;
