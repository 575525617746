import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './Badge.module.scss';

type BadgeType = 'Default' | 'Secondary' | 'Negative';

export interface BadgeProps {
  type: BadgeType;
  label: string;
  className?: string;
}

const classNameByType = (type: BadgeType): string => {
  switch (type) {
    case 'Default':
      return styles.default;
    case 'Secondary':
      return styles.secondary;
    case 'Negative':
      return styles.negative;
    default:
      return styles.default;
  }
};

const Badge = ({ type = 'Default', label, className }: BadgeProps) => {
  const { getGlobalTheme } = useTheme();

  const theme = getGlobalTheme();

  const classNameContainer = classNameByType(type);

  return (
    <div
      className={`${styles[theme]} ${styles.badgeContainer} ${classNameContainer} ${className}`}
    >
      <span className={styles.badgeText}>{label}</span>
    </div>
  );
};

export default Badge;
