import React, { useContext, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as translations from '../intl';
import { IntlContext } from '../../../../../intl';
import styles from './AppointmentItem.module.scss';
import Button from '../../../../../components/Button/Button';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { nurseService } from '../../../../../services';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../utils/sanitize';
import { phoneIcon } from '../constants';
import AppointmentInfoNAL from '../AppointmentInfo/AppointmentInfoNAL';
import ModalWithImage from '../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../components/ModalResult/ModalResult';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import ChevronGeneric from '../../../../../assets/icons/ChevronGeneric';
import { getStatus, STATUS_PENDING } from '../../../../../utils/status';
import Loading from '../../../../../components/Loading/Loading';
import StatusChip from '../../../../../componentsV2/Chip/StatusChip/StatusChip';

const AppointmentItemNAL = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { moreInfo, data } = props;
  const [showInfo, setShowInfo] = useState(false);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const history = useHistory();
  const [appointmentDetail, setAppointmentDetail] = useState('LOADING');
  const isPending =
    data.appointment_details.status === getStatus(STATUS_PENDING);

  const loadAppointmentData = async (id, setAppointmentDetail) => {
    const appointment = await nurseService.getReportDetail(id);
    setAppointmentDetail(appointment.data);
  };

  useEffect(() => {
    const callService = async () => {
      try {
        if (showInfo) {
          await loadAppointmentData(
            data.appointment_details.appointment_id,
            setAppointmentDetail
          );
        }
      } catch (error) {
        setAppointmentDetail('ERROR');
      }
    };
    callService();
  }, [idiom, data.appointment_details.appointment_id, showInfo]);

  const refreshPage = () => {
    history.go(0);
  };

  const cancelAppointment = async id => {
    try {
      actions.modal.showLoading();
      const cancel = await nurseService.cancelUserAppointment(id);
      actions.modal.hideLoading();
      if (cancel.status === 200) {
        actions.modal.showModal(
          false,
          false,
          <ModalInformation
            type="success"
            message={intl.TEXT_SUCCESS_CANCEL}
            clickBtn={refreshPage}
          />,
          true
        );
      }
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={intl.TEXT_ERROR_CANCEL} />
      );
    }
  };

  const showModalToCancel = async id => {
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.MODAL_TITLE_CONFIRMATION_CANCEL_APPOINTMENT}
        subtitle={intl.MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT}
      >
        <ModalResult
          textConfirm={intl.BUTTON_CONFIRM}
          textDeny={intl.BUTTON_DENY}
          buttonConfirmType="borderRed"
          clickConfirm={() => {
            cancelAppointment(id);
          }}
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true
    );
  };

  const showButtons = (showArrow = true) => {
    return (
      <>
        {isPending ? (
          <div
            className={`${styles.button} ${styles.buttonCreateClaim} ${styles[themes]}`}
          >
            <Button
              css={styles[themes]}
              type="borderBlue"
              onClick={() => {
                showModalToCancel(data.appointment_details.appointment_id);
              }}
            >
              {intl.TEXT_CANCEL_APPOINTMENT}
            </Button>
          </div>
        ) : (
          <span className={styles.status}>
            <StatusChip
              context="NAL"
              status={data.appointment_details.status}
            />
          </span>
        )}

        {showArrow && (
          <span
            className={`${styles.arrow} ${
              showInfo ? styles.showMoreActive : ''
            }`}
          >
            <ChevronGeneric
              onClick={() => setShowInfo(!showInfo)}
              aria-label={intl.IMAGE_ARROW}
              rotate={showInfo ? 180 : 0}
              width={20}
              height={20}
            />
          </span>
        )}
      </>
    );
  };

  return (
    <li className={`${styles.recentClaimsItem} ${styles[themes]}`}>
      <div className={styles.left}>
        <div className={`${styles.icon} ${styles[themes]}`}>
          <img src={phoneIcon} alt={intl.PHONE_ICON} />
        </div>
        <div className={`${styles.text} ${styles[themes]}`}>
          <strong>
            {data.patient_info.first_name} {data.patient_info.last_name}
          </strong>
          {intl.TEXT_EMAIL}: {data.patient_info.email}
          <span
            className={styles.moreInfo}
            dangerouslySetInnerHTML={{ __html: sanitize(moreInfo) }}
          />
        </div>
      </div>
      <div className={styles.center}>
        <span>
          {intl.TEXT_DATE}: {data.appointment_details.date}
        </span>
        <span>({data.appointment_details.timezone})</span>
      </div>
      <div
        className={
          showInfo ? `${styles.right} ${styles.hideButtons}` : styles.right
        }
      >
        {showButtons()}
      </div>
      {showInfo && (
        <>
          {appointmentDetail === 'LOADING' ? (
            <Loading />
          ) : appointmentDetail === 'ERROR' ? (
            <></>
          ) : (
            <div style={{ width: '100%' }}>
              <AppointmentInfoNAL
                idiom={idiom}
                appointment={appointmentDetail}
                intl={intl}
              />
              <div
                className={`${styles.boxButton} ${styles.buttonCreateClaim} ${styles[themes]}`}
              >
                {isPending && showButtons(false)}
              </div>
            </div>
          )}
        </>
      )}
    </li>
  );
};

export default withRouter(AppointmentItemNAL);
