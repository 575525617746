import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RecentItem from '../RecentItem/RecentItem';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../componentsV2/Button/Button';
import { isAllowedClaimReimbursmentUpdate } from '../../utils/claims_name';
import ClaimDetails from './ClaimDetails/ClaimDetail';
import { rejected } from '../../pages/Profile/ProfileClaims/StatusClaimBySubStatus';

import { APPEAL_ENABLE } from '../../utils/environments_variables';
import { StateContext } from '../StateContextParent/StateContextParent';
import { matchClaimsAndBenefits } from '../../pages/PrivateClaims/config_claim';
import Loading from '../Loading/Loading';
import { getIconByTheme } from '../../utils/benefitCategory';

const ListRecentClaims = props => {
  const { claims, benefits, numberToFilter, siteSection } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const [listBenefits, setListBenefits] = useState([]);
  const [loading, setLoading] = useState([]);
  const binOrPan = utils.getBin(true);
  const history = useHistory();
  const intl = translate(translations);

  const { isAfluent } = useContext(ThemeContext);

  const getInfoBenefit = value => {
    return (
      listBenefits &&
      listBenefits.filter(
        benefit =>
          benefit &&
          benefit.external_reference &&
          benefit.external_reference === value.benefit_external_reference
      )
    );
  };

  const renderClaims = data => {
    return data.slice(0, numberToFilter).map((value, index) => {
      const infoBenefit = getInfoBenefit(value);

      const htmlContent = siteSection === 'Profile' && (
        <ClaimDetails claim={value} />
      );
      const hasEditView = isAllowedClaimReimbursmentUpdate(
        value.benefit_external_reference
      );

      return (
        <RecentItem
          claimId={value.claim_id}
          isClaim={true}
          benefitName={infoBenefit[0] && infoBenefit[0].name}
          key={index}
          image={
            infoBenefit[0]
              ? getIconByTheme(infoBenefit[0].images, isAfluent())
              : ''
          }
          textDate={intl.TEXT_CREATED}
          number={value.claim_number}
          date={value.created_at}
          claimStatus={value.status}
          subStatus={
            value.statuses && value.statuses.sub_status
              ? value.statuses.sub_status
              : false
          }
          contentStatusValue={value}
          siteSection={siteSection}
          htmlContent={htmlContent}
          hasEditView={hasEditView}
          benefit_external_reference={value.benefit_external_reference}
          appealButton={
            value.statuses &&
            value.statuses.sub_status &&
            value.statuses.sub_status.name &&
            rejected.includes(value.statuses.sub_status.name) &&
            APPEAL_ENABLE ? (
              <Button
                type="Tertiary"
                trailingIcon="Chevron Right"
                id={`appeal-${value.claim_id}`}
                onClick={() =>
                  history.push(
                    `/claim-type/${value.benefit_external_reference}/${value.claim_id}/appeal`
                  )
                }
                label={intl.TEXT_APPEAL}
              />
            ) : null
          }
        />
      );
    });
  };

  useEffect(() => {
    async function matchBenefitsAndCreatedClaims() {
      try {
        setLoading(true);
        const matchListBenefits = await matchClaimsAndBenefits(
          claims,
          benefits,
          binOrPan,
          idiomForApi()
        );
        setListBenefits(matchListBenefits);
      } catch (error) {
        setListBenefits(benefits);
      } finally {
        setLoading(false);
      }
    }

    matchBenefitsAndCreatedClaims();
  }, []);

  return <>{loading ? <Loading /> : renderClaims(claims)}</>;
};

export default ListRecentClaims;
