export const MENU_ACCOUNT = {
  en_US: 'My account',
  es_ES: 'Mi cuenta',
  pt_BR: 'Minha conta',
};

export const SUBMENU_ACCOUNT_CONTACT_DETAILS = {
  en_US: 'Personal details',
  es_ES: 'Información personal',
  pt_BR: 'Informações pessoais',
};

export const SUBMENU_ACCOUNT_YOUR_CARDS = {
  en_US: 'My cards',
  es_ES: 'Mis tarjetas',
  pt_BR: 'Meus cartões',
};

export const SUBMENU_ACCOUNT_YOUR_CARD = {
  en_US: 'My card',
  es_ES: 'Mi tarjeta',
  pt_BR: 'Meu cartão',
};

export const SUBMENU_ACCOUNT_YOUR_BANK = {
  en_US: 'My bank information',
  es_ES: 'Mi información bancaria',
  pt_BR: 'Minhas informações bancárias',
};

export const SUBMENU_ACCOUNT_YOUT_DEPENDENTS = {
  en_US: 'My beneficiaries',
  es_ES: 'Mis beneficiarios',
  pt_BR: 'Meus beneficiários',
};

export const MENU_YOUR_CERTIFICATES = {
  en_US: 'My certificates',
  es_ES: 'Mis certificados',
  pt_BR: 'Meus bilhetes',
};

export const SUBMENU_CERTIFICATES_ACTIVE_CERTIFICATES = {
  en_US: 'Active certificates',
  es_ES: 'Certificados activos',
  pt_BR: 'Bilhetes válidos',
};

export const SUBMENU_CERTIFICATES_PAST_CERTIFICATES = {
  en_US: 'Expired certificates',
  es_ES: 'Certificados caducados',
  pt_BR: 'Bilhetes expirados',
};

export const MENU_YOUR_CLAIMS = {
  en_US: 'My claims',
  es_ES: 'Mis reclamos',
  pt_BR: 'Meus sinistros',
};

export const SUBMENU_CERTIFICATES_PEDING_DOCUMENTATION = {
  en_US: 'Pending documentation',
  es_ES: 'Documentación pendiente',
  pt_BR: 'Documentação pendente',
};

export const SUBMENU_CERTIFICATES_URDER_REVIEW = {
  en_US: 'Under review',
  es_ES: 'Bajo revisión',
  pt_BR: 'Em revisão',
};

export const SUBMENU_CERTIFICATES_TO_BE_PAID = {
  en_US: 'To be paid',
  es_ES: 'A pagar',
  pt_BR: 'Aguardando pagamento',
};

export const SUBMENU_CERTIFICATES_REJECTED = {
  en_US: 'Rejected',
  es_ES: 'Rechazado',
  pt_BR: 'Rejeitado',
};

export const SUBMENU_CERTIFICATES_CLOSED = {
  en_US: 'Closed',
  es_ES: 'Cerrado',
  pt_BR: 'Fechado',
};

export const MENU_SETTINGS = {
  en_US: 'Settings',
  es_ES: 'Configuración',
  pt_BR: 'Configuração',
};

export const MENU_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const SUBMENU_HELP_CONTACT_DETALIS = {
  en_US: 'Personal details',
  es_ES: 'Información personal',
  pt_BR: 'Informações pessoais',
};

export const SUBMENU_HELP_CARDS = {
  en_US: 'My cards',
  es_ES: 'Mis tarjetas',
  pt_BR: 'Meus cartões',
};

export const SUBMENU_HELP_BANK_INFORMATION = {
  en_US: 'My bank information',
  es_ES: 'Mi información bancaria',
  pt_BR: 'Minhas informações bancárias',
};

export const SUBMENU_HELP_DEPENDENTS = {
  en_US: 'My beneficiaries',
  es_ES: 'Mis beneficiarios',
  pt_BR: 'Meus beneficiários',
};

export const MENU_NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SUBMENU_NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const SUBMENU_ARCHIVED = {
  en_US: 'Archived',
  es_ES: 'Archivado',
  pt_BR: 'Arquivado',
};

export const SUBMENU_PERSONAL_DOCUMENTS = {
  en_US: 'Personal documents',
  es_ES: 'Documentos personales',
  pt_BR: 'Documentos pessoais',
};

export const SUBMENU_SAVING = {
  en_US: `My Savings`,
  pt_BR: `Minhas Poupanças`,
  es_ES: `Mis Ahorros`,
};

export const SUBMENU_SAVING_CERTIFICATES = {
  en_US: `Savings in certificates`,
  pt_BR: `Poupanças em Bilhetes`,
  es_ES: `Ahorros en certificados`,
};

export const SUBMENU_SAVING_CLAIMS = {
  en_US: `Savings in claims`,
  pt_BR: `Poupanças em sinistros`,
  es_ES: `Ahorros en reclamos`,
};

export const SUBMENU_VOICE_ID = {
  en_US: 'Voice ID registration',
  pt_BR: 'Registro de Voice ID',
  es_ES: 'Registro de Voice ID',
};

export const SUBMENU_VOUCHERS = {
  en_US: `My Vouchers`,
  pt_BR: `Meus Vouchers`,
  es_ES: `Mis Vouchers`,
};

export const SUBMENU_VOUCHERS_ACTIVE = {
  en_US: 'Actives',
  es_ES: 'Activos',
  pt_BR: 'Válidos',
};

export const SUBMENU_VOUCHERS_PAST = {
  en_US: 'Expired',
  es_ES: 'Vencidos',
  pt_BR: 'Expirados',
};

export const SUBMENU_OCT = {
  en_US: 'Your Visa direct payment information',
  es_ES: 'Tu información de Visa Direct',
  pt_BR: 'Sua informação de pagamento direto Visa',
};
