import React, { useContext, useState } from 'react';

import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { useEffect } from 'react';
import {
  getIncidentStartDate,
  getIncidentDescription,
  getTripPeriod,
  getCovering,
} from '../../../../utils/claimsAPI/TripUtils';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const TripDelayDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [tripInformation, setTripInformation] = useState({});
  const [incident, setIncident] = useState({});
  const [otherInsuranceCovering, setOtherInsuranceCovering] = useState([]);

  useEffect(() => {
    const tripInformation =
      (claimDetails && claimDetails.trip_information) || {};
    setTripInformation(tripInformation);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const otherInsuranceCovering =
      (claimDetails && claimDetails.other_insurance_covering) || [];
    setOtherInsuranceCovering(otherInsuranceCovering);
  }, [claimDetails]);

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={getTripPeriod(tripInformation, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_OF_DELAY}
          value={getIncidentStartDate(incident, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          value={getIncidentDescription(tripInformation)}
        />
      </div>
      {getCovering(otherInsuranceCovering, 'TRANSPORT') && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_REIMBURSED_BY_TRANSPORTATION_COMPANY}
            value={getCovering(otherInsuranceCovering, 'TRANSPORT')}
          />
        </div>
      )}
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} (${reimbursedCurrency})`}
          />
        </div>
      )}
    </div>
  );
};

export default TripDelayDetail;
