import React, { useContext } from 'react';
import moment from 'moment';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import { pt_BR } from '../../../../intl/idioms';
import * as translations from './intl';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const PriceProtectionDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  formatCustomDate(
    claimDetails.medical_service.assistance_finished_at,
    'DD/MM/YYYY',
    idiom,
    country
  );
  const assistanceStarted = formatCustomDate(
    claimDetails.medical_service.assistance_started_at,
    'DD/MM/YYYY',
    idiom,
    country
  );
  const assistanceFinished = formatCustomDate(
    claimDetails.medical_service.assistance_finished_at,
    'DD/MM/YYYY',
    idiom,
    country
  );
  const medicalCarePeriod = assistanceStarted + ' - ' + assistanceFinished;

  const doctorFullName =
    claimDetails.attending_physician.person.first_name +
    ' / ' +
    claimDetails.attending_physician.person.last_name;

  const totalClaimed =
    claimDetails.total_amount_claimed.value +
    ' (' +
    claimDetails.total_amount_claimed.currency +
    ')';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_HOSPITAL_NAME}
          value={claimDetails.medical_service.medical_center_name}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MEDICAL_CARE_PERIOD}
          value={medicalCarePeriod}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_DOCTOR_NAME} value={doctorFullName} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_EMERGENCY_DESCRIPTION}
          value={claimDetails.medical_service.medical_service_description}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DIAGNOSIS}
          value={claimDetails.medical_service.diagnosis}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TREATMENT_INDICATED}
          value={claimDetails.medical_service.medical_treatment_received}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_TOTAL_CLAIMED} value={totalClaimed} />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} (${reimbursedCurrency})`}
          />
        </div>
      )}
    </div>
  );
};

export default PriceProtectionDetail;
