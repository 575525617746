import { useIconColors } from '../../hooks/useIconColors';

const ChevronDownIcon = props => {
  const colors = useIconColors(props);

  return (
    <svg width={16} height={16} fill="none" {...props}>
      <path
        d="M15 5L9.41421 10.5858C8.63316 11.3668 7.36684 11.3668 6.58579 10.5858L1 5"
        stroke={props.color ?? colors.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronDownIcon;
