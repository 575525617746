import { useIconColors } from '../../hooks/useIconColors';

const NotificationsIcon = props => {
  const colors = useIconColors(props);

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M20.9 16.7V9.9C20.9 4.9 17 1 12 1S3.1 4.9 3.1 9.9v6.9H.3v2h23.4v-2h-2.8zM5.1 9.9C5.1 6 8.1 3 12 3s6.9 3 6.9 6.9v6.9H5.1V9.9z"
        style={{
          fill: colors.secondary,
        }}
      />
      <path
        style={{
          fill: colors.primary,
        }}
        d="M8.9 21h6.2v2H8.9z"
      />
    </svg>
  );
};

export default NotificationsIcon;
