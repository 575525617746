import React, { useContext, useState, useEffect } from 'react';

import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { toFixedOrDefault } from '../../../../utils/numbers';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';

const PriceProtectionDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const [purchasedItem, setPurchasedItem] = useState({});
  const [advertisedPrice, setAdvertisedPrice] = useState({});
  const [amountEstimationDamage, setAmountEstimationDamage] = useState({});

  useEffect(() => {
    const purchasedItem = (claimDetails && claimDetails.purchased_item) || {};
    setPurchasedItem(purchasedItem);

    const advertisedPrice =
      (claimDetails && claimDetails.advertised_price) || {};
    setAdvertisedPrice(advertisedPrice);

    const amountEstimationDamage =
      (claimDetails && claimDetails.amount_estimation_damage) || {};
    setAmountEstimationDamage(amountEstimationDamage);
  }, [claimDetails]);

  const getPurchaseDate = () =>
    purchasedItem && purchasedItem.purchase_date
      ? formatCustomDate(purchasedItem.purchase_date, 'YYYY-MM-DD', idiom)
      : '-';

  const getItemName = () => purchasedItem.label || '-';
  const getItemManufacturer = () => purchasedItem.manufacturer || '-';
  const getItemModel = () => purchasedItem.model_number || '-';
  const getDiscoveryDate = () =>
    advertisedPrice && advertisedPrice.discovery_date
      ? formatCustomDate(advertisedPrice.discovery_date, 'YYYY-MM-DD', idiom)
      : '-';

  const getRetailName = () =>
    (advertisedPrice && advertisedPrice.retail_name) || '-';

  const getTotalAmountClaimed = () => {
    let value = amountEstimationDamage.value
      ? toFixedOrDefault(amountEstimationDamage.value, 2, '')
      : '';

    const currency = amountEstimationDamage.currency
      ? amountEstimationDamage.currency
      : '';

    return value ? `${value}${currency && ` (${currency})`}` : '-';
  };

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_DATE}
          value={getPurchaseDate()}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_ADVERTISEMENT_DATE}
          value={getDiscoveryDate()}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_ITEM_PURCHASED}
          value={getItemName()}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={getItemManufacturer()}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_MODEL} value={getItemModel()} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_ADVERTISEMENT_STORE}
          value={getRetailName()}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_PRICE_DIFFERENCE}
          value={getTotalAmountClaimed()}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${reimbursedValue} (${reimbursedCurrency})`}
          />
        </div>
      )}
    </div>
  );
};

export default PriceProtectionDetail;
