import { joinClassNames } from '../../utils/classNames';
import { LinkProps } from './Link.types';
import styles from './Link.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { forwardRef } from 'react';
import ExternalLinkIcon from '../../assets/iconsV2/ExternalLinkIcon';
import { IconProps } from '../../@types/Icons';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import NavHashLink from '../../components/NavHashLink/NavHashLink';

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      label,
      ariaLabel,
      href,
      target = '_blank',
      disabled = false,
      icon = true,
      surface = 'OnSurface',
      id,
      className,
      internal,
      onClick,
    }: LinkProps,
    ref
  ) => {
    const { getGlobalTheme } = useTheme();
    const theme = getGlobalTheme();
    const intl = useMemoTranslations(translations);

    const getClassname = () =>
      joinClassNames(
        styles.link,
        surface === 'OnSurface' ? styles.onSurface : styles.inverse,
        disabled ? styles.disabled : undefined,
        styles[theme],
        className
      );

    const commomProps = {
      id,
      ref,
      className: getClassname(),
      'aria-disabled': disabled,
      'aria-label': `${ariaLabel || label}${
        internal ? '' : ` - ${intl.NEW_TAB}`
      }`,
      rel: internal ? undefined : 'noopener noreferrer',
      target: internal ? undefined : target,
      onClick,
    };

    const childrenProps = { label, surface, icon, disabled };

    return internal ? (
      <NavHashLink {...commomProps} to={href || ''}>
        <LinkChildren {...childrenProps} />
      </NavHashLink>
    ) : (
      <a {...commomProps} href={href}>
        <LinkChildren {...childrenProps} />
      </a>
    );
  }
);

const LinkChildren = ({
  label,
  surface,
  icon,
  disabled,
}: {
  label: string;
  surface: IconProps['surface'];
  icon: boolean;
  disabled: boolean;
}) => (
  <>
    {label}
    {icon && (
      <ExternalLinkIcon
        surface={surface}
        color="Monotone"
        disabled={disabled}
      />
    )}
  </>
);

Link.displayName = 'Link';

export default Link;
export * from './Link.types';
